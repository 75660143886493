import { StaticQuery, graphql } from 'gatsby'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import { Container, Row, Col } from '../components/html/Layout'
import { Link } from '../components/html/Links'
import { H3, H5, P, H2 } from '../components/html/Fonts'
import { baseStyles } from '../components/html/_config'
import { urlMaker } from '../utility/blog';
import TimeIndicator from '../components/molecules/TimeIndicator';
import Layout from '../components/layout';
import SectionBlock from '../components/page-elements/SectionBlock';
import { ImgStoriesOfCare } from '../components/images/ImgStoriesOfCare';
import SEO from '../components/seo';



const StoriesOfCarePage = () => (
  <Layout>
    <SEO 
      title="Stories of Care From Our Family's 12 Years of Experience"
      description="Caring for aging family members was one of the most difficult seasons of our life. We hope that sharing our stories of caring for Nick & Clara will encourage you and your family's journey through providing care for family."
      keywords={[]}
    />
    <SectionBlock
      title='Stories of Care'
      subtitle='Helpful Articles on Caring for Aging Family'
      img={<ImgStoriesOfCare/>}
    >
      <P>Caring for aging family members was one of the most difficult seasons of our life. We hope that sharing our stories of caring for Nick & Clara will encourage you and your family's journey through providing care for family.</P>
    </SectionBlock>
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(    
            sort: {
              fields: [frontmatter___date]
              order: DESC
            }
          ) {
            edges {
              node {
                fileAbsolutePath
                frontmatter {
                  title
                  date
                  subtitle
                  image
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Container fluid css={css`background: ${baseStyles.colorQuinary};`}>
          <Row>
            {data.allMarkdownRemark.edges.length > 0 ?
              data.allMarkdownRemark.edges.map((edge, i) => (
                edge.node &&
                  <Col
                    as='article'
                    xs={3} 
                    key={i} 
                    css={css`margin-bottom: ${baseStyles.baseMargin}px`}
                  >
                    <Link 
                      css={css`
                        border-bottom: 1px solid ${baseStyles.colorQuaternary};
                        &:hover {
                          opacity: 0.6;
                          &:after {
                            width: 0%;
                          }
                        }
                      `}
                      to={urlMaker(edge.node.fileAbsolutePath)}
                    >
                      <div 
                        css={css`
                          width: 100%;
                          height: 125px;
                          overflow: hidden;
                          margin-bottom: 15px;
                        `}
                      >
                        <img 
                          css={css`max-width: 100%;`}
                          alt={edge.node.frontmatter.subtitle}
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                      <H3 css={css`margin-bottom: 15px; color: ${baseStyles.colorPrimary}`}>{edge.node.frontmatter.title}</H3>
                      <H5>{edge.node.frontmatter.subtitle}</H5>
                      <TimeIndicator sm time={edge.node.frontmatter.date}/>
                    </Link>
                  </Col>
              ))
              :
              <Container as='section' fluid css={css`background: ${baseStyles.colorQuinary}; padding: 60px 0!important; img { max-width: 100%; }`}>
                <Row as='section' css={css`max-width: ${baseStyles.maxWidth}px; margin: 0 auto!important;`}>
                  <Col>
                    <H2 css={css`margin-bottom: 0;`}>Stories of Care Coming Soon...</H2>
                  </Col>
                </Row>
              </Container>
            }
          </Row>
        </Container>
      )}
    />
    </Layout>
)

export default StoriesOfCarePage